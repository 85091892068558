











































































import { Component } from 'vue-property-decorator'
import VueBase from '@/VueBase'
@Component({
  name: 'Yunxiao',
})
export default class Yunxiao extends VueBase {}
